<template>
  <div>
    <app-breadcrumb :items="breadcrumbs" />
    <loading :show="loading.page">
      <filter-reports
          ref="filter"
          :filter.sync="filter"
          show-seasons-filter
          show-market-places-filter
          show-error-status-filter
          :enable="isEnableFilter"
          @initFilter="initFilter()"
      />
      <table-report
          ref="table"
          pagination
          searchable
          :fields-search="['error_reason']"
          :filter="filter"
          :is-loading-page="loading.page"
          :is-loading-table.sync="loading.table"
          :is-init-filter="isInitFilter"
          default-sort-by="sku"
          :is-unhandled-changes="isUnhandledChanges"
          :force-fetch-data-from-api.sync="forceFetchDataFromApi"
          @handle-new-changes="handleNewChanges()"
      >
        <template #table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: (field.key === 'error_status' || field.key === 'error_reason') ? '200px' : '70px' }"
          >
        </template>
        <template #cell(error_status)="data">
          <v-select
              v-model="data.item.error_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="errorStatusOptionsComputed"
              label="title"
              :reduce="option => option.value"
              class="w-100"
              :clearable="false"
              style="width: 1000px;"
              @input="updateStatus()"
          />
        </template>
      </table-report>
    </loading>
    <div
        v-show="isUnhandledChanges"
        class="footer fixed"
    >
      <b-button
          variant="primary"
          class="m-1"
          @click="saveChanges()"
      >
        {{ $i18n.t('Save Changes') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TableReport from "@/views/reports/components/table/index.vue";
import AppBreadcrumb from "@/layouts/components/AppBreadcrumb.vue";
import FilterReports from "@/views/reports/components/FilterReports.vue";
import LiveShareErrorDataService from "@/views/reports/services/LiveShareErrorDataService";

export default {
  components: {
    FilterReports,
    AppBreadcrumb,
    TableReport,
  },
  data() {
    return {
      reportDataService: new LiveShareErrorDataService(),
      filter: null,
      enableFilter: false,
      loading: {
        page: false,
        chart: false,
        table: false,
      },
      isInitFilter: false,
      isUnhandledChanges: false,
      forceFetchDataFromApi: false,
      errorStatusOptions: [
        'in_progress',
        'processed',
        'open',
        'in_clarification'
      ],
    }
  },
  computed: {
    isEnableFilter() {
      return !this.loading.page && !this.loading.table
    },
    breadcrumbs() {
      return [
        {
          text: 'reports.names.live-share-error-report',
          active: false,
        }
      ]
    },
    errorStatusOptionsComputed() {
      const transOptions = []
       this.errorStatusOptions.forEach(option => {
         transOptions.push({
           value: option,
           title: this.$i18n.t(`reports.utils.live-share-error-report.error-status-options.${option}`)
         })
      })
      return transOptions;
    },
  },
  watch: {
    loading: {
      handler() {
        if (!this.loading.chart && !this.loading.table) {
          this.loading.page = false
        }
      },
      deep: true,
    }
  },
  methods: {
    initFilter() {
      this.isInitFilter = true;
      this.$refs.table.refresh()
    },
    updateStatus() {
      this.isUnhandledChanges = !(JSON.stringify(this.$refs.table.viewItems) === JSON.stringify(this.$refs.table.orginalViewItems));
    },
    saveChanges() {
      const data = this.$refs.table.viewItems.map(obj => {
        return {
          id: obj.id,
          error_status: obj.error_status,
        };
      });
      this.reportDataService.updateErrorStatus(data).then(response => {
        this.isUnhandledChanges = false
        this.forceFetchDataFromApi = true
        this.$refs.table.refresh();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
      })
    },
    cancelChanges() {
      this.$refs.table.viewItems = JSON.parse(JSON.stringify(this.$refs.table.orginalViewItems));
    },
    async handleNewChanges(){
      const result = await new Promise((resolve) => {
        this.$swal({
          title: this.$i18n.t('You have recent changes'),
          text: this.$i18n.t("Do you want to save them?"),
          icon: this.$i18n.t('warning'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Yes, Save'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(res => {
          resolve(res); // Resolve the promise with the SweetAlert result
        });
      });
      if (result.value) {
        this.saveChanges()
      } else {
        this.cancelChanges()
      }
      this.isUnhandledChanges = false
      this.$refs.table.refresh()
    },
  }
}

</script>
<style scoped>
.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #f0f0f0; /* Set the background color for the footer */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
